import Axios from 'axios';
import { AxiosResponseType } from '@/utils/network/axios-helper';



export interface ITopicConfig {
  list: ITopicConfigItem[];
}

export interface ITopicConfigItem {
  key: string;
  list: TopicChildItem[];
  name: string;
}

export interface TopicChildItem {
  children: ITopicConfigItem[];
  name: string;
  value: number;
}

export interface Child {
  key?: string;
  list?: ChildList[];
  name?: string;
}
export interface ChildList {
  children: string[];
  name: string;
  value: number;
}

/**
 * 获取搜索条件列表
 */
export const getTopicSearchConfig = async (params: { module_id: number; }): Promise<AxiosResponseType<ITopicConfig>> => {
  return Axios.post('/api/exercise/get-search-config', params);
};


export interface IPageListRequest {
  page: number;
  page_size: number;
  /**
   * 试卷类型：1剑雅 2机经
   */
  category?: number;
  /**
   * 搜索内容
   */
  content?: string;
  /**
   * 模块id
   */
  module_id?: number;
  /**
   * 题目分类
   */
  subject_type?: number;
  /**
   * 话题
   */
  topic?: number;
  /**
   * 年份
   */
  year?: number;

  is_accurate?: number;
}


export interface ITopicList {
  list: ITopicItem[];
  total: number;
}

export interface ITopicItem {
  content: string;
  id: number;
  img: string;
  last_time: string;
  record_id: number;
  score: number;
  /**
   * 0：未练过 1：未完成 2：已完成
   */
  status: number;
  title: string;
  step: number;
}
/**
 * 根据模块id获取内容列表
 */
export const getPageListByModule = async (params: IPageListRequest): Promise<AxiosResponseType<ITopicList>> => {
  return Axios.post('/api/exercise/get-page-list-by-module', params);
};

/**
 * 获取小作文搜索条件列表 
 */
export const getEssaySearchConfig = async (): Promise<AxiosResponseType<ITopicConfig>> => {
  return Axios.post('/api/exercise/get-essay-search-config', {});
};

export interface IEssayRequest {
  /**
   * 分类
   */
  category?: number;
  /**
   * 搜索字段
   */
  content?: string;
  page?: number;
  page_size?: number;
  /**
   * 题型
   */
  type?: number;
}


export interface EssayTopicList {
  list: EssayTopic[];
  total: number;
  [property: string]: any;
}

export interface EssayTopic {
  content: string;
  id: number;
  img_desc: string;
  img_url: string;
  record_id: number;
  status: number;
  title: string;
  update_time: number;
  [property: string]: any;
}

/**
 * 获取小作文
 */
export const getEssayPaperList = async (params: IEssayRequest): Promise<AxiosResponseType<EssayTopicList>> => {
  return Axios.post('/api/exercise/get-essay-paper-list', params);
};

/************************************************************************************************************************************ */
export interface IGetLastRecordInfo_Request {
  module_id: number;
  paper_id: number;
}
export interface IGetLastRecordInfo_Response {
  record_id: number;
  status: number;
}

/**
 * 获取paper最新record信息
 */
export const getLastRecordInfo = async (params: IGetLastRecordInfo_Request): Promise<AxiosResponseType<IGetLastRecordInfo_Response>> => {
  return Axios.post('/api/exercise/get-last-record-info', params);
};

