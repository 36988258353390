import { createSlice } from '@reduxjs/toolkit';
import type { IArgumentId, IDirectionId, IPositionId } from '@/modules/Writing/ArgumentsAndEvidances/arguments-and-evidances.type';

export interface IExample {
  // content?: string;
  // loading: boolean;
  score: number;
}

export interface ICompositionStateType {
  selectedArguments: Array<[IPositionId, IDirectionId, IArgumentId]>;
  examples: IExample[];
}

export const initialState: ICompositionStateType = {
  selectedArguments: [],
  examples: [],
};

export const compositionSlice = createSlice({
  name: 'composition',
  initialState,
  reducers: {
    selectArgument: (state, action: { payload: { argument: [number, number, number]; }; } ) => {
      const nArg = action.payload.argument;
      if (!state.selectedArguments.find((arg) => (arg[0] === nArg[0] && arg[1] === nArg[1] && arg[2] === nArg[2]))) {
        state.selectedArguments = [...state.selectedArguments, nArg];
      }
    },
    unSelectArgument: (state, action: { payload: { argument: [number, number, number]; }; } ) => {
      const nArg = action.payload.argument;
      state.selectedArguments = state.selectedArguments.filter((arg) => (arg[0] !== nArg[0] || arg[1] !== nArg[1] || arg[2] !== nArg[2]));
    },
    addExample: (state, action: { payload: number; }) => {
      state.examples.push({ score: action.payload });
    },
  },
});

export const { selectArgument, unSelectArgument, addExample } = compositionSlice.actions;
