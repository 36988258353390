/**
 * local storage
 */

import { APP_NAME } from '../const';

const generateKey = (v: string) => `${APP_NAME}_${v}`;

export class LocalStorage {
  /**
   * 设置缓存
   */
  static setStorage<T = unknown>(
    key: string,
    value: T,
    withNamespace = true,
  ): boolean {
    try {
      const val = JSON.stringify(value);
      window.localStorage.setItem(withNamespace ? generateKey(key) : key, val);
    } catch (error) {
      return false;
    }
    return true;
  }

  /**
   *
   * @param key 获取缓存
   * @returns
   */
  static getStorage<T>(key: string, withNamespace = true): T {
    let res: unknown;
    const val = window.localStorage.getItem(withNamespace ? generateKey(key) : key);
    if (val) {
      res = JSON.parse(val);
    }
    return res as T;
  }
  /**
   * 当前key是否存在
   */
  static hasKey(key: string, withNamespace = true): boolean {
    return !!window.localStorage.getItem(withNamespace ? generateKey(key) : key);
  }
  /**
   * 删除 key
   */
  static deleteStorage(key: string, withNamespace = true): void {
    window.localStorage.removeItem(withNamespace ? generateKey(key) : key);
  }
  /**
   * 清理storage
   */
  static clearStorage(): void {
    window.localStorage.clear();
  }
}
