import Axios from 'axios';
import { AxiosResponseType } from '@/utils/network/axios-helper';

interface Data {
    content: string;
    id: number;
}
export const getWritingIdeas = async (params: {paper_id: number; type: number;}): Promise<AxiosResponseType<Data>> => {
  return Axios.post('/api/exercise/get-expand-info', params);
};

export const saveWritingIdeas = async (params:{content: string; paper_id: number; type: number;}): Promise<AxiosResponseType<{id:number;}>> => {
  return Axios.post('/api/exercise/save-expand-info', params);
};
