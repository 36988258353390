import Axios from 'axios';
import { AxiosResponseType } from '@/utils/network/axios-helper';
import { IFollowReadScoreQuery } from '@/stores/slices/listeningEnhanceSlice';

export const getPaperInfo = async (id: number, type?: number[]): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/listening/get-paper-info', { id, type: type || [] });
};

export const getListenEnhanceInfo = async (id: number, type?: number[]): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/listening/get-paper-intensive-info', { id, type: type || [] });
};

export const getFollowReadScoreList = async (id: number, type?: number[]): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/listening/get-oral-score-record-list', { paper_id: id, type: type || [] });
};

export const getFollowReadScoreInfo = async (params: IFollowReadScoreQuery): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/listening/oral-score', params);
};

export const listeningSaveRecord = async (params: any): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/listening/save-record', params);
};

export const getRecordInfo = async (record_id: number): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/listening/get-record-info', { id: record_id });
};

export const ListeningCollateAnswer = async (params: any) => {
  return Axios.post('/api/listening/submit-collate-answer', params);
};

export const getRecordAnswerInfo = async (record_id: number) => {
  return Axios.post('/api/listening/get-record-answer-info', { record_id });
};


export const listenSaveRecord = async (params: any): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/listening/save-record', params);
};