import { IGuideType } from '../arguments-and-evidances.type';
import { forEachArgument } from './iterater';

export const getEvidences = (guide: {
  list: IGuideType['list']; // PracticeInfo[guide]
}) => {
  const results: any[] = [];

  forEachArgument(guide, (arg) => {
    results.push({ pid: arg.id, children: arg.list?.map((evi) => evi.id) || [] });
  });

  return results;
};

