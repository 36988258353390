import { AxiosResponseType, post } from '@/utils/network/axios-helper';

export interface IReadingSearchConfig {
  name: string;
  value: number;
}

export interface IReadingSearchConfigItem extends IReadingSearchConfig {
  children: IReadingSearchConfig[];
}

export interface IGetSearchConfig_Response {
  jian_ya: IReadingSearchConfigItem[];
  ji_jing: IReadingSearchConfigItem[];
  yu_ce: IReadingSearchConfigItem[];
}
// 获取搜索条件
export const getSearchConfig = async (): Promise<AxiosResponseType<IGetSearchConfig_Response>> => {
  return await post('/api/reading/get-new-search-config');
};

export interface IReadingPaperWithRecordInfo {
  content: string;
  /**
   * 难易程度，1、易 2、中 3、难
   */
  difficulty: number;
  /**
   * 做过的人数
   */
  done_num: number;
  id: number;
  last_status: number;
  last_time: number;
  record_id: number;
  /**
   * 最近一次自己作对的题目数
   */
  self_num: number;
  title: string;
  /**
   * 题目总数
   */
  total_num: number;
  average_num: number;
  type: number;
}

export interface IReadingPaperSection {
  id: number;
  name: string;
  score: number;
  children: IReadingPaperWithRecordInfo[];
}

export interface IReadingPaperBook {
  name: string;
  id: number;
  children: IReadingPaperSection[];
}

export interface IGetReadingPaperList_Response {
  list: IReadingPaperBook[];
  total: number;
}

// 获取剑雅列表
export const getReadingPaperList = async (data: { 
  type?: number; 
  page: number; 
  page_size?:number;
}): Promise<AxiosResponseType<IGetReadingPaperList_Response>> => {
  return await post('/api/reading/get-exam-list', data);
};

export interface IGetReadingPaperListJiJing_Request {
  page?: number;
  page_size?: number;
  /**
   * 考试id
   */
  unit?: number;
}

export interface IGetReadingPaperListJiJing_Response {
  list: IReadingPaperSection[];
  total: number;
}
// 获取机经列表
export const getReadingPaperListJiJing = async (params: IGetReadingPaperListJiJing_Request): Promise<AxiosResponseType<IGetReadingPaperListJiJing_Response>> => {
  return await post('/api/reading/get-ji-jing-exam-list', params);
};

export interface SearchReadingPaperRequest {
    paper_ids?: number[];
    /**
     * 1剑雅 2机经
     */
    pid: number;
    search_content: string;
    topic: number;
    type?: number;
    page?: number;
    page_size?: number;
}

export interface SearchReadingPaperResponst {
    list: IReadingPaperWithRecordInfo[];
    score: number;
    total: number;
}

export const getSearchReadingPaperList = async (params: SearchReadingPaperRequest): Promise<AxiosResponseType<SearchReadingPaperResponst>> => {
  return await post('/api/reading/get-paper-list', params);
};


