import { GlobalEventProxy } from '@/utils/event-proxy';

export interface ISystemActionMap {
  'system:keyboard_height_change': {
    payload: {
      height: number;
    };
  };
  'system:touch_point': {
    payload: {
      // 0: TOUCH_ACTION_DOWN, 1: TOUCH_ACTION_UP, 2: TOUCH_ACTION_MOVE. 3: TOUCH_ACTION_CANCEL
      action: number;
      name: 'TOUCH_ACTION_DOWN' | 'TOUCH_ACTION_UP' | 'TOUCH_ACTION_MOVE' | 'TOUCH_ACTION_CANCEL';
      x: number;
      y: number;
    };
  };
  'system:pencil': {
    payload: {
      havePencil: boolean;
    };
  };
  'system:cookie:update': {
    payload: {
      key: string; value: string; expires: number;
    };
  };
  'system:apple:pencil': {
    payload: any;
  };
  'system:user:info': {
    payload: any;
  };
}

export type ISystemRecieveEvent<K extends keyof ISystemActionMap = 'system:keyboard_height_change'> = {
  action: K;
  data: ISystemActionMap[K]['payload'];
};

export type IUICallback<K extends keyof ISystemActionMap = 'system:keyboard_height_change'> = (arg: ISystemActionMap[K]['payload']) => void;

export const isIUIEvent = (data: any): data is ISystemRecieveEvent => {
  if (typeof data === 'object' && !!data) {
    // TODO: 类型判断需要做修改
    if (typeof data.action === 'string' && data.data) {
      return true;
    }
  }
  return false;
};


export const generator = (s: GlobalEventProxy): {
  onKeyboardHeightChange: (args: IUICallback<'system:keyboard_height_change'>) => void;
  onTouchPoint: (args: IUICallback<'system:touch_point'>) => void;
  onPencilContactChange: (args: IUICallback<'system:pencil'>) => void;
  onPencilSwitchChange: (args: IUICallback<'system:apple:pencil'>) => void;
  onUpdateUserInfo: (args: IUICallback<'system:user:info'>) => void;
  onUpdateCookie: (args: IUICallback<'system:cookie:update'>) => void;
} => {
  return {
    onKeyboardHeightChange: (fn) => {
      const event = 'system:keyboard_height_change';
      s.subscribeEvent(event, fn);
    },
    onTouchPoint: (fn) => {
      const event = 'system:touch_point';
      s.subscribeEvent(event, fn);
    },
    onPencilContactChange: (fn) => {
      const event = 'system:pencil';
      s.subscribeEvent(event, fn);
    },
    onPencilSwitchChange: (fn) => {
      const event = 'system:apple:pencil';
      s.subscribeEvent(event, fn);
    },
    onUpdateUserInfo: (fn) => {
      const event = 'system:user:info';
      s.subscribeEvent(event, fn);
    },
    onUpdateCookie: (fn) => {
      const event = 'system:cookie:update';
      s.subscribeEvent(event, fn);
    },
  };
};

