import { IGuideType } from '../arguments-and-evidances.type';
import { forEachDirection } from './iterater';

export const getArgumentId = (guide: {
  list: IGuideType['list']; // PracticeInfo[guide]
}) => {
  const results: any[] = [];

  forEachDirection(guide, (dir) => {
    results.push({ pid: dir.pid, children: dir.list?.map((arg) => arg.id) || [] });
  });

  return results;
};
