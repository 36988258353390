import { IArgumentType, IDirectionType, IEvidenceType, IGuideType } from '../arguments-and-evidances.type';

export const forEachDirection = (guide: {
  list: IGuideType['list']; // PracticeInfo[guide]
}, callback: (argument: IDirectionType) => void) => {
  guide.list.forEach((pos) => {
    // 两个立场
    pos.list?.forEach(callback);
  });
};

export const forEachArgument = (guide: {
  list: IGuideType['list']; // PracticeInfo[guide]
}, callback: (argument: IArgumentType) => void) => {
  forEachDirection(guide, (dir) => {
    dir.list?.forEach((arg) => {
      callback(arg);
    });
  });
};

export const forEachEvidence = (guide: {
  list: IGuideType['list']; // PracticeInfo[guide]
}, callback: (argument: IEvidenceType) => void) => {
  forEachArgument(guide, (arg) => {
    arg.list?.forEach(callback);
  });
};
