import { createSlice } from '@reduxjs/toolkit';

export interface CoordinateFollowType {
  coordinateFollow: any;
}

const initialState: CoordinateFollowType = {
  coordinateFollow: null
};

export const coordinateFollow = createSlice({
  name: 'coordinateFollow',
  initialState,
  reducers: {
    updateCoordinateFollow: (state: any, action: { payload: any; }) => {
      state.coordinateFollow = action.payload;
    },
  },
});

export const { updateCoordinateFollow } = coordinateFollow.actions;
export default coordinateFollow;
