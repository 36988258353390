/* eslint-disable no-debugger */
import store from '@/stores/index';
import { LoginModal, PermisssionModal } from '@/modules/Permissions';

// 权限类型
export enum permissionsType {
  /**
   * 是否试用
   */
  is_approval = 'is_approval',
  /**
   * 练习
   */
  is_coach = 'is_coach',
  /**
   * 生成范文
   */
  is_essay = 'is_essay',
  /**
   * 考试
   */
  is_review = 'is_review'
}

const guardPathMapping = {
  '/practice': permissionsType.is_coach,
  '/evaluation-record': permissionsType.is_review,
  '/composition': permissionsType.is_essay
} as any;

const guardPaths = ['/practice', '/evaluation-record', '/composition'];
const passPahts = ['history'];

/**
 * 是否已经监听过
 */
let isSubscribeReduxStore = false;

/**
 * 是否已经请求获取到了用户的授权
 */
let isFetchedUserInfo = false;

export const guardByUserPermissions = async (permissions: any, perKey?: permissionsType) => {

  if (!permissions) {
    const state = store.getState();
    permissions = state.user.permissions;
  }

  if (!permissions) {
    LoginModal.open();
    return false;
  }

  if (perKey) {
    const permission = permissions[perKey];
    if (!permission) {
      PermisssionModal.open(true);
      return false;
    } else {
      return true;
    }
  }

  if (passPahts.find(p=> window.location.href.includes(p))){
    return true;
  }

  guardPaths.forEach(item => {
    const isRoute = window.location.href.indexOf(item) !== -1;
    if (isRoute) {
      const perKey = guardPathMapping[item];
      const permission = permissions[perKey];
      if (!permission) {
        PermisssionModal.open(true);
      }
    }
  });
};

window && window.addEventListener('load', () => {
  if (!isSubscribeReduxStore) {
    store.subscribe(() => {
      isSubscribeReduxStore = true;
      const state = store.getState();
      const permissions = state.user.permissions;
      if (!isFetchedUserInfo && permissions) {
        isFetchedUserInfo = true;
        guardByUserPermissions(permissions);
      }
    });
  }
});
