import { IEvidenceType, IArgumentType, IPositionType, IGuideType } from '../arguments-and-evidances.type';

export const mergeEvidenceList = (oEvidenceList: IEvidenceType[], nEvidenceList: IEvidenceType[]): IEvidenceType[] => {
  const result = [...oEvidenceList];
  nEvidenceList.forEach((evi) => {
    const nEnv = oEvidenceList.find((e) => e.id === evi.id);
    if (!nEnv) {
      result.push(evi);
    }
  });

  return result;
};

export const mergeArgumentList = (oArgumentList: IArgumentType[], nArgumentList: IArgumentType[]): IArgumentType[] => {
  const result = [...oArgumentList];
  nArgumentList.forEach((arg) => {
    const nArg = oArgumentList.find((e) => e.id === arg.id);
    if (!nArg) {
      result.push(arg);
    } else {
      nArg.list = mergeEvidenceList(nArg.list || [], arg.list || []);
    }
  });

  return result;
};

export const mergePosition = (oPositionList: IPositionType[], nPositionList: IPositionType[]): [IPositionType, IPositionType] => {
  const result = [...oPositionList];
  nPositionList.forEach((nPos) => {
    const oPos = oPositionList.find((e) => e.id === nPos.id);
    if (!oPos) {
      result.push(nPos);
    } else {
      oPos.list = mergeArgumentList(oPos.list || [], nPos.list || []);
    }
  });
  if (result.length > 2) {
    // eslint-disable-next-line i18n/no-chinese-character
    console.error('有超过两个描述立场');
  }
  return [result[0], result[1]];
};

export const mergeGuide = (oGuide: { list: IGuideType['list']; }, nGuide: { list: IGuideType['list']; }): { list: IGuideType['list']; } => {
  return { list: mergePosition(oGuide.list, nGuide.list) };
};
