import { v4 as uuidv4 } from 'uuid';

import jsCookie from 'js-cookie';
import { LogActionFuncMap } from '@/utils/jsBirdge/groups/log';
import { BusinessActionFuncMap } from '@/utils/jsBirdge/groups/business';
import { logError } from '../logger/arms-config';
import { SystemActionFuncMap } from './groups/system';
import { FeedbackActionFuncMap } from './groups/feedback';
import { PageActionFuncMap } from './groups/page';

/**
 * BasicJsBridgeArg interface
 * @template Action - A key from JsBridgeActionFuncMap
 * @property {JsBridgeActionFuncMap[Action]['args']} data - The arguments for the action
 * @property {Action} action - The action to be performed
 * @property {string} call_id - The unique identifier for the call
 */
interface BasicJsBridgeArg<Action extends keyof JsBridgeActionFuncMap> {
  data: JsBridgeActionFuncMap[Action]['args'];
  action: Action;
  call_id: string;
}


/**
 * BasicJsBridgeResult interface
 * @template Action - A key from JsBridgeActionFuncMap
 * @property {boolean} ok - The status of the result
 * @property {JsBridgeActionFuncMap[Action]['result']} data - The result data
 * @property {string} msg - The message associated with the result
 * @property {string} call_id - The unique identifier for the call
 */
interface BasicJsBridgeResult<Action extends keyof JsBridgeActionFuncMap> {
  ok: boolean;
  data?: JsBridgeActionFuncMap[Action]['result'];
  msg: string;
  call_id: string;
}


type JsBridgeActionFuncMap = SystemActionFuncMap
  & FeedbackActionFuncMap
  & PageActionFuncMap
  & LogActionFuncMap
  & BusinessActionFuncMap

const isAndroid = jsCookie.get('platform') === 'android';

/**
 * jsBridgeCall function
 * @template K - A key from JsBridgeActionFuncMap
 * @param {K} action - The action to be performed
 * @param {BasicJsBridgeArg<K>['data']} args - The arguments for the action
 * @returns {Promise<BasicJsBridgeResult<K>>} - A promise that resolves with the result of the action
 */
const jsBridgeCall = async <K extends keyof JsBridgeActionFuncMap>(action: K, args: BasicJsBridgeArg<K>['data']): Promise<BasicJsBridgeResult<K>> => {

  const callArgs: BasicJsBridgeArg<K> = {
    call_id: uuidv4(),
    action: action,
    data: args || {}
  };
  console.log('[JSBrdge Call]', action, JSON.stringify(args));
  if (isAndroid) {
    if (!!(window as any).X5WebView?.sendData && typeof (window as any).X5WebView.sendData === 'function') {
      return (window as any).X5WebView.sendData(callArgs.call_id, action, JSON.stringify(callArgs.data));
    }
    // console.error('window.X5WebView.sendData is not defined');
    const error = new Error('window.X5WebView.sendData is not defined');
    logError(error);
    return Promise.reject(error);
  }

  if (!window.flutter_inappwebview?.callHandler) {
    // console.error('window.flutter_inappwebview.callHandler is not defined');
    const error = new Error('window.flutter_inappwebview.callHandler is not defined');
    logError(error);
    return Promise.reject(error);
  }

  return window?.flutter_inappwebview?.callHandler<K>?.('dyy-bridge-call', callArgs);
};


const jsBridgeApi = {
  showAndroidKeyboard: (content: string) => {
    return jsBridgeCall('system:keyboard:open', {
      content, type: 'text'
    });
  },
  copy: (content: string) => {
    return jsBridgeCall('system:copy', {
      content, type: 'text'
    });
  },
  // 逐步增加震动时长
  haptic: (type: 1 | 2 | 3 | 4 | 5) => {
    return jsBridgeCall('system:haptic', {
      type: type
    });
  },

  toast: (content: string) => {
    return jsBridgeCall('feedback:toast', {
      content
    });
  },
  openPage: (page: string) => {
    return jsBridgeCall('system:window:open', {
      page,
      redirectUrl: window.location.href,
    });
  },
  open: (url: string) => {
    return jsBridgeCall('page:route:open', {
      url
    });
  },
  close: () => {
    return jsBridgeCall('page:route:close', {});
  },
  log: (content: string, level: 'debug' | 'info' | 'warn' | 'error' = 'info') => {
    const now = new Date();
    return jsBridgeCall('log:send', {
      log: {
        level: level,
        ua: navigator.userAgent,
        lang: navigator.language,
        url: window.location.href,
        web_timestamp: now.getTime(),
        web_ISOTime: now.toISOString(),
        content
      }
    });
  },
  showMoreMenu: (biz: BusinessActionFuncMap['biz:menu:show']['args']['biz'], data: Omit<BusinessActionFuncMap['biz:menu:show']['args'], 'biz'>) => {
    return jsBridgeCall('biz:menu:show', {
      biz,
      ...data,
    });
  },
  getPencilState: () => {
    return jsBridgeCall('system:bluetooth:state', {});
  }
};

export default jsBridgeApi;









