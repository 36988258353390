import Axios from 'axios';
import { AxiosResponseType } from '@/utils/network/axios-helper';

interface Question{
  id: number;
  title:string;
  option: Array<{item:string;explanation:string;}>;
  answer: number;
  summary: string;
  number: 1;
}
interface QuestionReviewList{
  list:Question[];
  record_id: number;
}
/**
 * 拉取审题题目
 * @param id // 试卷id
 * @param type // 1 非整篇 2整篇
 * @returns 
 */
export const getQuestionReviewList = async (paper_id: number, type: number): Promise<AxiosResponseType<QuestionReviewList>> => {
  return Axios.post('/api/exercise/get-examining-question-list', { paper_id, type});
};

export interface IAnswerQuestion {
    list: List[];
    paper_id: number;
    record_id: number;
    type: number;
    [property: string]: any;
}

interface List {
    analysis: string;
    answer: number;
    question_id: number;
    [property: string]: any;
}
// 答题
export const answerQuestionReview = async (params: IAnswerQuestion): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/exercise/examining-doing-record-save', params);
};

export interface AnswerList {
    list: AnswerListItem[];
    [property: string]: any;
}

export interface AnswerListItem {
    analysis?: string;
    answer?: number;
    question_id?: number;
    [property: string]: any;
}

/**
 * 获取答题记录
 * @param params 
 * @returns 
 */
export const getQuestionReviewRecord = async (params: {paper_id: number; type: number; record_id: number;}): Promise<AxiosResponseType<AnswerList>> => {
  return Axios.post('/api/exercise/get-examining-doing-record-info', params);
};