import { GlobalEventProxy } from '@/utils/event-proxy';

export interface IUIActionMap {
  'ui:base_font_size': {
    payload: {
      step: number;
    };
  };
}

export type IUIEvent<K extends keyof IUIActionMap = 'ui:base_font_size'> = {
  action: K;
  data: IUIActionMap[K]['payload'];
};

export type IUICallback<K extends keyof IUIActionMap = 'ui:base_font_size'> = (arg: IUIActionMap[K]['payload']) => void;

export const isIUIEvent = (data: unknown): data is IUIEvent => {
  if (typeof data === 'object' && !!data) {
    // TODO: 类型判断需要做修改
    if (typeof (data as IUIEvent).action === 'string' && (data as IUIEvent).data) {
      return true;
    }
  }
  return false;
};

export const generator = (s: GlobalEventProxy): {
  onFontSizeChange: (args: IUICallback<'ui:base_font_size'>) => void;
} => {
  return {
    onFontSizeChange: (fn) => {
      const event = 'ui:base_font_size';
      s.subscribeEvent(event, fn);
    },
  };
};

