import { remove } from 'lodash-es';

export class GlobalEventProxy {
  /**
   * 事件池
   */
  public subscribers: any;

  constructor() {
    this.subscribers = {};
  }

  /**
   * 订阅事件的部分
   * @param event 
   * @param callback 
   * @param displayName 组件名
   */
  public subscribeEvent(event: string, callback: any, displayName?: string) {
    if (!this.subscribers[event]) {
      this.subscribers[event] = [];
    }
    const find = this.subscribers[event];
    const item = { displayName: displayName || null, callback: callback };
    let subscribers = [...find];
    if (displayName) {
      subscribers = remove(find, function (n: any) {
        return n.displayName && n.displayName === displayName;
      });
      this.subscribers = subscribers;
    }
    this.subscribers[event].push(item);
  }

  /**
   * 发布事件的部分
   * @param event 
   * @param data 
   */
  public publishEvent(event: string, data?: any) {
    // console.log('===> publish event', event, JSON.stringify(data), JSON.stringify(Object.keys(this.subscribers)));
    if (this.subscribers[event]) {
      // console.log('===> publish event list', Object.keys(this.subscribers), this.subscribers[event]);
      this.subscribers[event].forEach((item: any) => {
        item?.callback(data);
      });
    }
  }

  /**
   * 取消订阅的分布
   * @param event 
   * @param callback 
   */
  public unsubscribeEvent(event: string, callback: any) {
    if (this.subscribers[event]) {
      this.subscribers[event] = this.subscribers[event].filter((item: any) => item.callback !== callback);
    }
  }
  /**
   * 取消所有订阅
   */
  public unsubscribeAllEvent() {
    this.subscribers = {};
  }
}
