import { AxiosResponseType, post } from '@/utils/network/axios-helper';

export interface IListeningSearchConfig {
  name: string;
  value: number;
}

export interface IListeningSearchConfigItem extends IListeningSearchConfig {
  children: IListeningSearchConfig[];
}

export interface IGetSearchConfig_Response {
  jian_ya: IListeningSearchConfigItem[];
  ji_jing: IListeningSearchConfigItem[];
  yu_ce: IListeningSearchConfigItem[];
}
// 获取搜索条件
export const getSearchConfig = async (): Promise<AxiosResponseType<IGetSearchConfig_Response>> => {
  return await post('/api/listening/get-search-config');
};

export interface IListeningPaperWithRecordInfo {
  content: string;
  /**
   * 难易程度，1、易 2、中 3、难
   */
  difficulty: number;
  /**
   * 做过的人数
   */
  done_num: number;
  id: number;
  last_status: number;
  last_time: number;
  record_id: number;
  /**
   * 最近一次自己作对的题目数
   */
  self_num: number;
  title: string;
  /**
   * 题目总数
   */
  total_num: number;
  average_num: number;
  type: number;
}

export interface IListeningPaperSection {
  id: number;
  name: string;
  score: number;
  children: IListeningPaperWithRecordInfo[];
}

export interface IListeningPaperBook {
  name: string;
  id: number;
  children: IListeningPaperSection[];
}

export interface IGetlisteningPaperList_Response {
  list: IListeningPaperBook[];
  total: number;
}

// 获取剑雅列表
export const getListeningPaperList = async (data: { type?: number; page: number;page_size?:number; }): Promise<AxiosResponseType<IGetlisteningPaperList_Response>> => {
  return await post('/api/listening/get-exam-list', data);
};

export interface IGetListeningPaperListJiJing_Request {
  page?: number;
  page_size?: number;
  /**
   * 考试id
   */
  unit?: number;
}

export interface IGetListeningPaperListJiJing_Response {
  list: IListeningPaperSection[];
  total: number;
}
// 获取机经列表
export const getListeningPaperListJiJing = async (params: IGetListeningPaperListJiJing_Request): Promise<AxiosResponseType<IGetListeningPaperListJiJing_Response>> => {
  return await post('/api/listening/get-ji-jing-exam-list', params);
};


export interface SearchListeningPaperRequest {
    paper_ids?: number[];
    /**
     * 1剑雅 2机经
     */
    pid: number;
    search_content: string;
    topic: number;
    type?: number;
    page?: number;
    page_size?: number;
}

export interface SearchListeningPaperResponst {
    list: IListeningPaperWithRecordInfo[];
    score: number;
    total: number;
}

export const getSearchListeningPaperList = async (params: SearchListeningPaperRequest): Promise<AxiosResponseType<SearchListeningPaperResponst>> => {
  return await post('/api/listening/get-paper-list', params);
};