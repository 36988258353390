
export const getSubPage = () => {
  const p = window.location.hash.match(/[^#/]+/);
  if (p && typeof p[0] === 'string') {
    return p[0];
  }
  return 'default';
};

export const getPage = () => {
  const p = window.location.pathname.match(/[^/]+/);
  if (p && typeof p[0] === 'string') {
    return p[0];
  }
  return 'writing-list';
};

export const getPageName = () => {
  return `ielts--${getPage()}--${getSubPage()}`;
};
