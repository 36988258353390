import Axios from 'axios';
import { AxiosResponseType } from '@/utils/network/axios-helper';

export interface ISearchRequest {
    /**
     * 搜索字段
     */
    content?: string;
    page?: number;
    page_size?: number;
    /**
     * 类型 1听力 2阅读 2写作
     */
    exam_type?: number;
}


export interface ISearchResponse {
    list: ISearchItem[];
    total: number;
}

export interface ISearchItem {
    paper_id: number;
    type: number;
    paper_type: number;
    title: string;
    content: string;
    record_id: number;
    difficulty: number;
    done_num: number;
    total_num: number;
    average_num: number;
    self_num: number;
    last_status: number;
    last_time: number;
    highlight_content:string[];
    highlight_question_title: string[];
    highlight_title: string[];
}

/**
 * 
 */
export const getContentSearchList = async (params: ISearchRequest): Promise<AxiosResponseType<ISearchResponse>> => {
  return Axios.post('/api/homepage/search', params);
};