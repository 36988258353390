

import axios, { AxiosRequestConfig } from 'axios';
import { checkHasToken } from '@/modules/Permissions';

export interface AxiosResponseType<T = never> {
  code: number;
  data: T;
  msg: string;
  header?: { [key: string]: string; };
}

interface PostConfig extends AxiosRequestConfig {
  // 是否需要鉴权
  needAuth?: boolean;
}

export async function post<T>(url: string, data = {}, config?: PostConfig): Promise<AxiosResponseType<T>> {
  const { needAuth, ...restConfig } = config || {};

  if (needAuth) {
    checkHasToken();
  }

  return new Promise<AxiosResponseType<T>>((resolve, reject) => {
    axios.post<any, AxiosResponseType<T>, any>(url, data, restConfig).then((res) => {
      const body = res as unknown as AxiosResponseType<T>;
      if (body.code !== 200) {
        reject({
          ...body,
          msg: body.msg,
          message: body.msg,
        });
        return;
      }
      resolve(body);
    }).catch(e => {
      console.error(e);

      reject(e);
    });
  });
}
