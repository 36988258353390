import Axios from 'axios';
import { AxiosResponseType } from '@/utils/network/axios-helper';
import { IArgumentType, IDirectionType } from '@/modules/Writing/ArgumentsAndEvidances/arguments-and-evidances.type';

export interface IUpdateRecommendedArgumentReq {
  exercises_id: number; // 题目id
  list: Array<{ //
    pid: number; // 角度id
    children: Array<{
      id: number;
    }>; // 论点id
  }>;
  is_append?: number; // 是否是追加模式 1-是 2-否，默认2
}

export interface IUpdateRecommendedArgumentRes {
  exercises_id: number; // 题目id
  list: IDirectionType[];
}

/**
 * 更换论点
 * @param params IUpdateRecommendedEvidencesReq
 * @returns 
 */
export const updateRecommendedArgument: (params: IUpdateRecommendedArgumentReq) => Promise<AxiosResponseType<IUpdateRecommendedArgumentRes>> = async (params) => {
  return Axios.post('/api/exercise/change-argument', params);
};

export interface IUpdateRecommendedEvidencesReq {
  exercises_id: number; // 题目id
  list: Array<{ // 当前的论点
    pid: number; // 论点
    children: number[]; // 论据
  }>;
}

export interface IUpdateRecommendedEvidencesRes {
  exercises_id: number; // 题目id
  list: IArgumentType[]; // 论点=>论据
}

/**
 * 更换论点
 * @param params IUpdateRecommendedEvidencesReq
 * @returns 
 */
export const updateRecommendedEvidences: (params: IUpdateRecommendedEvidencesReq) => Promise<AxiosResponseType<IUpdateRecommendedEvidencesRes>> = async (params) => {
  return Axios.post('/api/exercise/change-evidences', params);
};
