import { AxiosResponseType, post } from '@/utils/network/axios-helper';

interface Request {
    selected: IItemSelected[];
    /**
     * //1写作 2阅读 3听力
     */
    type: number;
}

export interface IItemSelected {
    key: string;
    value: number;
}

export interface IBasicConfigResponse{
    list: IConfigItem[];
}

export interface IConfigItem {
    key: string;
    list: IConfigItemChild[];
    name: string;
}

export interface IConfigItemChild {
    name: string;
    value: number;
}
/**
 * 获取基础训练搜索列表
 * @param params 
 * @returns 
 */
export const getSearchConfig = async (params: Request): Promise<AxiosResponseType<IBasicConfigResponse>> => {
  return await post('/api/basic-training/get-search-config', params);
};

interface GetQusetionRequest{
  page: number;
  page_size: number;
  course: number;
  [property: string]: any;
}

export interface IQuestionListResponse {
    fields: string[];
    list: IBasicQuestion[];
    total: number;
}

export interface IBasicQuestion {
    difficulty: number;
    difficulty_text: string;
    done_num: number;
    grammar: number;
    grammar_text: string;
    id: number;
    record_id: number;
    record_status: number;
    result: number;
    schedule: string;
    score: string;
    title: string;
    topic: number;
    topic_text: string;
    type: number;
    type_text: string;
}

/**
 * 
 * @param params 
 * @returns 
 */
export const getQuestionList = async (params: GetQusetionRequest): Promise<AxiosResponseType<IQuestionListResponse>> => {
  return await post('/api/basic-training/get-group-list', params);
};