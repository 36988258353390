
// 页面初始化时执行

import jsCookie from 'js-cookie';
import { onUpdateCookie, onUpdateUserInfo } from '@/utils/jsBirdge/listen';
import { authCookieDomain } from '@/utils/env';
import { updateUserInfo } from './auth';

const notInitUserInfoPage = [
  '/mobile-writing-upload-image/'
];


export function initPermission() {
  // console.log('Current Token', jsCookie.get('token'));

  if (!notInitUserInfoPage.includes(window.location.pathname)) {
    updateUserInfo();
  }
  onUpdateUserInfo(() => {
    updateUserInfo();
  });
  onUpdateCookie((ele) => {
    jsCookie.set(ele.key, ele.value, {
      domain: authCookieDomain,
      expires: new Date(ele.expires),
    });
    console.log('Listen Current Token', jsCookie.get('token'));
    console.log('All Cookies', JSON.stringify(jsCookie.get()));
    if (ele.key === 'token') {
      updateUserInfo();
    }
  });

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      updateUserInfo();
    }
  });
}
